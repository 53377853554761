import React from 'react'

import Layout from '../../../components/layout'
import Container from '../../../components/container'
import ResidenceDetails from '../../../components/residence-details'

import Photo1 from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PHOTOS/SC_01.jpg'
import Photo2 from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PHOTOS/SC_02.jpg'
import Photo3 from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PHOTOS/SC_03.jpg'
import Photo4 from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PHOTOS/SC_04.jpg'
import Photo5 from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PHOTOS/SC_05.jpg'
import Photo1s from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PHOTOS/450X280/SC-SM_01.jpg'
import Photo2s from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PHOTOS/450X280/SC-SM_02.jpg'
import Photo3s from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PHOTOS/450X280/SC-SM_03.jpg'
import Photo4s from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PHOTOS/450X280/SC-SM_04.jpg'
import Photo5s from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PHOTOS/450X280/SC-SM_05.jpg'
import Process1 from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/SCH_01.jpg'
import Process2 from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/SCH_02.jpg'
import Process3 from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/SCH_03.jpg'
import Process4 from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/SCH_04.jpg'
import Process5 from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/SCH_05.jpg'
import Process6 from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/SCH_06.jpg'
import Process1s from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/450X280/SCH-SM_01.jpg'
import Process2s from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/450X280/SCH-SM_02.jpg'
import Process3s from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/450X280/SCH-SM_03.jpg'
import Process4s from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/450X280/SCH-SM_04.jpg'
import Process5s from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/450X280/SCH-SM_05.jpg'
import Process6s from '../../../images/work/17_CARMEL_VALLEY_SCHUMACHER/PROCESS/450X280/SCH-SM_06.jpg'

const Residence03 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="RANCH HOUSE"
        residenceLocation="CARMEL VALLEY, CALIFORNIA"
        infoList={['Remodel of existing 2,860 SF single family residence. Exterior improvements.', 'Design - 2016', 'Construction - 2017', 'Owner-Builder', 'Structural Engineer: Structures Engineering, Inc', 'Photos: Anatoly Ostretsov, Lisa Schumacher']}
        testimonialList={[]}
        photos={[Photo1,Photo2,Photo3,Photo4,Photo5]}
        photosSmall={[Photo1s,Photo2s,Photo3s,Photo4s,Photo5s]}
        processPhotos={[Process1,Process2,Process3,Process4,Process5,Process6]}
        processPhotosSmall={[Process1s,Process2s,Process3s,Process4s,Process5s,Process6s]}
        />
    </Container>
  </Layout>
)

export default Residence03;
